<template>
  <pop-up :width="width"  :closePopupEvent="closePopupCB">
    <div slot="header">
      <h6 class="popup-header">FAQ</h6>
    </div>
    <app-loader v-show="showLoading"></app-loader>    
    <div class="faq-container">
      <div class="vlt-faqs-data" v-if="faqData">
        <div class="vlt-faq-div">
          <div class="vlt-faq-tickets" v-if="faqData.faqdata && faqData.faqdata.length">
            <div
              class="vlt-faq-sec"
              v-for="(faq, i) in faqData.faqdata"
              :key="faq.faqno"
              :id="`faq-sec-${i}`"
              :class="isSecActive(i)"
              :data-action="getDataAction(i)"
            >
              <div class="faq-qstn" @click="toggleAnswer(i)">
                <div class="faq-collapse">
                  <img
                    :src="getCollapseIco(i)"
                    alt="down arrow"
                    class="collapse-ico"
                    :id="`collapse-ico-${i}`"
                  />
                </div>
                <p>{{faq.faqquestion}}</p>
                <div class="clearfix"></div>
              </div>
              <div class="faq-answ" :id="`faq-answ-${i}`">
                <p v-if="!Array.isArray(faq.faqanswer)">{{faq.faqanswer}}</p>
                <p v-else v-for="(ans,i) in faq.faqanswer" :key="i">{{ans}}</p>
              </div>
            </div>
          </div>
        </div>
        <div class="contact-support">
          <div class="support-btn" v-if="!showContSuppBtn">
            <button
              type="button"
              class="btn-support"
              ref="contactSupportBtn"
              aria-label="Signup"
              @click="togglesupport(true);"
            >CONTACT SUPPORT</button>
          </div>
          <div class="support-group" v-else>
            <form-error v-show="formError" :message="formError"></form-error>
            <form  @submit.prevent="contactSupportCB" autocomplete="off">
              <div class="vlt-input-group textarea-block">
                <textarea
                  rows="4"
                  class="form-textarea-inp"
                  v-model="message"
                  maxlength="256"
                  placeholder="Enter your comments"
                ></textarea>
                <img
                  src="@/assets/icons/detail_close_icon.svg"
                  alt="closes"
                  class="support-close"
                  @click="closeSupportCB()"
                  loading="lazy"
                />
              </div>
              <div class="support-btn-submit">
                <button
                  type="submit"
                  class="btn-support"
                  ref="contactSupportBtn"
                  aria-label="Signup"
                >CONTACT SUPPORT</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </pop-up>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from "vuex";
import { EventBus } from "@/main";
import appMixins from "@/mixins/appMixins";

export default {
  name: "PopupHelp",
  data() {
    return {
      width: "50%",
      faqData: undefined,
      showLoading: true,
      formError: undefined,
      showContSuppBtn: false,
      toggleIco: undefined,
      showLess: require('@/assets/icons/right_arrow.svg'),
      showMore: require('@/assets/icons/downArrow.svg'),
      message: '',
      appUser: undefined,
      flagAutologin:true
    }
  },
  props: [
    'closePopupCB'
  ],
  watch: {
    subscriberId() {
      if (this.subscriberId) {
        if(localStorage.getItem('appUser')){
          this.appUser = JSON.parse(localStorage.getItem('appUser'));
    }
      }
    },
  },
  props: ["closePopupCB"],
  computed: {
    ...mapGetters(["subscriberId"])
  },
  methods: {
    ...mapActions([
      'getFaqJSON','actNotifyVendor'
    ]),
    closePopup () {
      this.closePopupCB();
    },
    getFaqsData() {
      this.getFaqJSON()
        .then(response => {
          if (response.ok && response.status === 200) {
            return response.json()
          }
          this.showLoading = false;
        })
        .then(data => {
          if(data){
            this.faqData = data;
            this.showLoading = false;
          }
        })
        .catch(error => {
          this.showLoading = false;
          console.log("Help Error", error);
          this.formError = "Error Occured, please try again later.";
        });
    },
    getCollapseIco(index) {
      return index === 0 ? this.showMore : this.showLess;
    },
    getDataAction(index) {
      return index === 0 ? "minus" : "plus";
    },
    isSecActive(index) {
      return index === 0 ? "active" : "";
    },
    toggleAnswer(index) {
      let clickedSec = document.getElementById(`faq-sec-${index}`);
      if (!clickedSec) return;
      let action = clickedSec.getAttribute("data-action");
      let collapseIco = document.getElementById(`collapse-ico-${index}`);

      if (action == "minus") {
        clickedSec.classList.remove("active");
        clickedSec.setAttribute("data-action", "add");
        collapseIco.src = this.showLess;
      } else {
        clickedSec.classList.add("active");
        clickedSec.setAttribute("data-action", "minus");
        collapseIco.src = this.showMore;
      }
    },
    togglesupport(state) {
      if (!this.subscriberId) {
        let data = {
          popup: 'ContactPopup'
        }
        EventBus.$emit('LoginPopup', data)
        this.closePopup();
      } else {
        this.showContSuppBtn = state;
      }      
    },
    contactSupportCB() {
      if (!this.isFormValid()) return;
      this.showLoading = true;
      let data = {
        email: this.appUser?.email,
        title: 'Dear Support Team',
        subject: "test",
        message: this.message
      };

      this.actNotifyVendor(data).then(response => {

      this.showLoading = false;

        if (response.error) {
          this.formError = response.reason;
        } else {
          this.closePopup();
        }
      }).catch(error => {
         if (error.status === 401) {
            // this.unauthorisedHandler();
            if (this.flagAutologin) {
              this.commonAutoLogin(this.contactSupportCB);
              this.flagAutologin = false;
              return;
            }
          }
      });
    },
    isFormValid () {
      return this.isMessageValid();
    },
    isMessageValid () {
      if (!this.message) {
        this.formError = 'Message is required';
        return false;
      } else if (this.message.startsWith(" ")) {
        this.formError = 'Remove Space At Beginning';
        return false;
      }
      this.formError = '';
      return true;
    },
    closeSupportCB() {
      this.showContSuppBtn = false;
    }
  },
  created () {
    if(localStorage.getItem('appUser')){
      this.appUser = JSON.parse(localStorage.getItem("appUser"));
    }
    //EventBus.$on("ContactSupport", this.togglesupport(true));
    this.getFaqsData();
  },
  components: {
    "pop-up": () =>
      import(/* webpackChunkName: "Popup" */ "@/components/Shared/popup.vue"),
    "form-error": () =>
      import(
        /* webpackChunkName: "formError" */ "@/components/Shared/templates/formError.vue"
      ),
    "app-loader": () =>
      import(
        /* webpackChunkName: "appLoader" */ "@/components/Shared/templates/appLoader.vue"
      )
  },
  mixins: [appMixins]

};
</script>

<style lang="scss" scoped>
@import "~sass/modules/_variables.scss";
@import "~sass/modules/_fonts.scss";
@import "~sass/modules/_mediaQueries.scss";

.faq-container {
  border-top: 1px solid $clr-light-hlt-1;
}
.vlt-faqs-data {
  margin: 0 0 0 7%;
}
.vlt-faq-div {
  overflow-x: hidden;
  overflow-y: auto;
  max-height: 50vh;
  padding-right: 7%;
}
.collapse-ico {
  transition: 0.5s;
}
.vlt-faq-sec {
  border-bottom: 1px solid $clr-light-hlt-1;
  padding: 8px 0;
  margin-bottom: 10px;
  &:last-child {
    border: none;
  }
  &:hover {
    .faq-qstn {
      p {
        transform: translateX(5px);
        transition-timing-function: ease-in-out;
        transition: 0.3s;
      }
      .faq-collapse {
        img {
          opacity: 1;
        }
      }
    }
  }
}
.faq-qstn {
  cursor: pointer;
  user-select: none;
  display: flex;
  p {
    color: $font-clr-gray;
    font-family: $font-family;
    font-weight: $font-weight-semibold;
    float: left;
    font-size: 1rem;
    padding: 10px;
  }
  .faq-collapse {
    img {
      vertical-align: middle;
      opacity: 0.7;
    }
  }
}
.faq-answ {
  height: 0;
  opacity: 0;
  overflow: hidden;
  transition: height 0s, opacity 0.3s ease;
  padding-left: 9%;
  p {
    color: $font-clr-white-1;
    font-family: $font-family;
    font-weight: $font-weight-regular;
    font-size: 0.9rem;
    line-height: 1.5rem;
  }
}
.active {
  .faq-answ {
    height: auto;
    opacity: 1;
  }
}
.contact-support {
  padding-right: 7%;
}
.support-btn {
  margin: 6% 0;
}
.support-btn-submit {
  margin-top: 2%;
}
.support-group {
  margin-top: 7%;
  margin-bottom: 5%;
}
.btn-support {
  font-family: $font-family;
  font-weight: $font-weight-bold;
  padding: 7px;
  font-size: 15px;
  cursor: pointer;
  border: 0;
  text-transform: uppercase;
  width: 45%;
  letter-spacing: 1px;
  color: $font-clr-white;
  background-color: $theme-primary;
  border-radius: 10px;
  opacity: 1;
  margin: auto;
  display: block;
}
.form-textarea-inp {
  height: 80px;
  resize: none;
  line-height: 1.5rem;
  font-size:1rem;
  font-weight:$font-weight-regular;
}
::placeholder {
  color: $clr-white;
}
.textarea-block{
  position:relative;
}
.support-close {
  position: absolute;
  cursor: pointer;
  float: right;
  width: calc(1rem - 4px);
  top:4px;
  right: 4px;
  opacity: 0.7;
  &:hover {
    opacity: 1;
  }
}

</style>
